<template>
  <header class="header-section">
    <nav class="navbar">
      <div class="logo">
        <v-img
          max-height="22"
          max-width="22"
          src="../../assets/logo.svg"
          alt="logo"
        />
        <div>
          <router-link @click="$router.push('/')" to="/"
            >BB Utvikling</router-link
          >
        </div>
      </div>

      <ul class="nav-menu" role="navigation">
        <li>
          <router-link @click="toggleMenu()" :to="{ name: 'omoss' }"
            >om oss</router-link
          >
        </li>
        <li>
          <router-link @click="toggleMenu()" :to="{ name: 'ProjectLists' }"
            >prosjekter</router-link
          >
        </li>

        <li>
          <router-link @click="toggleMenu()" :to="{ name: 'contact' }"
            >Kontakt Oss</router-link
          >
        </li>
      </ul>
      <button class="hamburger" @click="toggleMenu()">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
    </nav>
  </header>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    toggleMenu() {
      const hamburger = document.querySelector(".hamburger");
      const navMenu = document.querySelector(".nav-menu");
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/global.less";

.header-section {
  background-color: @menubar;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 504;
  -webkit-box-shadow: 0px 1px 10px @primary;
  -moz-box-shadow: 0px 1px 10px @primary;
  box-shadow: 0px 1px 10px @primary;
  transition: 0.2s;
  .navbar {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .logo {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .v-img {
        margin-top: -7px !important;
      }
      a {
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 800;
        font-size: 1.08rem;
        color: @light;
      }
    }
    .nav-menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 60px;

      transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      list-style: none;
      li {
        a {
          text-decoration: none;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 1rem;
          color: @light;
          display: inline-block;
          white-space: nowrap;

          &::after {
            content: "";
            display: block;
            width: 0;
            height: 4px;
            background: @secondary;
            transition: width 0.3s;
          }
          &:hover::after {
            width: 100%;
          }
        }

        .router-link-exact-active {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background: @secondary;
            transition: width 0.3s;
          }
        }
      }
    }

    .hamburger {
      display: none;
      cursor: pointer;

      .bar {
        display: block;
        width: 30px;
        height: 3px;
        margin: 5px auto;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        background-color: @light;
      }
    }
  }

  @media (max-width: 900px) {
    .navbar {
      padding: 0 0;
      .hamburger {
        display: block;
        margin-right: 18px;
      }
      .hamburger.active {
        .bar {
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(1) {
            transform: translateY(8px) rotate(45deg);
          }
          &:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
          }
        }
      }
      .logo {
        margin-left: 18px;
      }
      .nav-menu {
        position: fixed;
        display: block;
        background-color: @primary;
        width: 100vw;
        height: 100vh;
        text-align: center;
        visibility: hidden;
        top: -100%;
        opacity: 0;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
        -webkit-transition: visibility 0s 0.5s, opacity 0.5s linear;
        -moz-transition: visibility 0s 0.5s, opacity 0.5s linear;
        -ms-transition: visibility 0s 0.5s, opacity 0.5s linear;
        -o-transition: visibility 0s 0.5s, opacity 0.5s linear;
        li {
          margin: 90px 0;
        }
      }
      .nav-menu.active {
        top: 0%;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s linear;
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -ms-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
      }
    }
  }
}
</style>
