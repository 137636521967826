<template>
  <div class="home-intro">
    <v-container class="home-intro__wrap">
      <div class="home-intro__wrap__title">
        <h2>Hva gjør vi</h2>
      </div>
      <div
        class="home-intro__wrap__desc"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <p>
          Vi bygger og utvikler eiendomsprosjekter, med fokus på
          kostnadseffektiv småhusbebyggelse som 2-mannsboliger, flermannsboliger
          og rekkehus.
        </p>
      </div>
    </v-container>
  </div>
</template>
<style lang="less" scoped>
@import "../../styles/global.less";
.home-intro {
  width: 100%;
  background-color: rgba(241, 249, 224, 0.3);
  min-height: 150px;

  padding: 1.5rem 0;
  &__wrap {
    max-width: 1140px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 30% 60%);
    justify-content: left;
    align-items: center;
    gap: 1rem;
    &__title {
      h2 {
        color: @dark;
        font-size: 1.6rem;
        letter-spacing: 1px;
        line-height: 1.5;
        font-weight: 600;
        padding: 0 1rem;
        @media (max-width: 900px) {
          font-size: 1.5rem;
        }
      }
    }
    &__desc {
      font-size: 1.3rem;
      letter-spacing: 0;
      line-height: 1.6;
      font-weight: 400;
      @media (max-width: 900px) {
        font-size: 1.2rem;
        margin-top: 8px;
        padding: 0 1rem;
      }
    }
    @media (max-width: 900px) {
      display: block;
    }
  }
}
</style>
