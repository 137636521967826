<template>
  <v-app>
    <NavbarPrimary />
    <v-main>
      <router-view />
    </v-main>
    <FooterPrimary />
  </v-app>
</template>

<script>
import NavbarPrimary from "@/components/navbar/NavbarPrimary.vue";
import FooterPrimary from "@/components/footer/FooterPrimary.vue";

export default {
  components: { NavbarPrimary, FooterPrimary },
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style lang="less"></style>
