<template>
  <div class="home">
    <hero-section />
    <intro-section />
    <new-projects />
    <our-task />
  </div>
</template>

<script>
import HeroSection from "@/components/home/HeroSection.vue";
import IntroSection from "@/components/home/IntroSection.vue";
import NewProjects from "@/components/home/NewProjects.vue";
import OurTask from "@/components/home/OurTask.vue";
export default {
  components: {
    HeroSection,
    IntroSection,
    NewProjects,
    OurTask,
  },
};
</script>
<style lang="less" scoped>
.home {
}
</style>
