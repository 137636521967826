<template>
  <div class="footer">
    <div class="footer__wrapper">
      <div class="box">
        <h2>
          <router-link to="/">BB Utvikling</router-link>
        </h2>
      </div>
      <div class="box">
        <h2>Kontakt</h2>
        <div>
          <v-icon size="20" color="white">mdi-phone</v-icon
          ><a href="tel:+4746137000" class="phone">461 37 000</a>
        </div>
        <div>
          <v-icon size="20" color="white">mdi-email</v-icon
          ><a href="mailto:post@boligogbyggutvikling.com" class="email"
            >post@boligogbyggutvikling.no</a
          >
        </div>
        <div></div>
      </div>
      <div class="box">
        <ul class="footer-lists" role="footer navigation">
          <li>
            <router-link @click="toggleMenu()" :to="{ name: 'omoss' }"
              >Om oss</router-link
            >
          </li>
          <li>
            <router-link @click="toggleMenu()" :to="{ name: 'ProjectLists' }"
              >Prosjekter</router-link
            >
          </li>

          <li>
            <router-link @click="toggleMenu()" :to="{ name: 'contact' }"
              >Kontakt Oss</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="text-center footer__card">
      <v-card-text>
        @{{ new Date().getFullYear() }} —
        <strong><router-link to="/">BB Utvikling</router-link></strong>
      </v-card-text>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
};
</script>
<style lang="less" scoped>
@import "../../styles/global.less";
.footer {
  background: @primary;
  width: 100%;

  &__wrapper {
    max-width: 1140px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 40% 30% 30%);
    align-items: left;
    justify-items: left;
    gap: 10px;
    padding: 1rem;
    padding-top: 1.5rem;
    .box:nth-child(1) {
      text-align: left;
      a {
        color: @light;
        text-decoration: none;
        font-weight: 700;
        font-size: 1rem;
        display: block;
        text-align: left;
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    .box:nth-child(2) {
      text-align: left;
      h2 {
        color: @light;
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 10px;
      }
      div {
        white-space: nowrap;
        .phone {
          text-decoration: none;
          color: @light;
          margin-left: 6px;
          margin-bottom: 10px;
          display: inline-block;
        }
        .email {
          text-decoration: none;
          color: @light;
          margin-left: 6px;
          margin-bottom: 10px;
          display: inline-block;
        }
        .facebook {
          text-decoration: none;
          color: @light;
          margin-left: 6px;
        }
      }
    }

    .box:nth-child(3) {
      text-align: left;
      h2 {
        color: @light;
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 5px;
      }
      .footer-lists {
        list-style: none;
        display: block;
        li {
          padding: 4px 0;
          a {
            text-decoration: none;
            color: @light;
            white-space: nowrap;
          }
        }
      }
    }
    @media (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      align-items: left;
      justify-items: center;
      gap: 1rem;
    }
  }
  &__card {
    color: @light;
    font-size: 0.8rem;
    background: @secondary;
    color: @primary;
    font-weight: 800;
    padding: 4px 0;
    display: block;
    a {
      color: @primary;
      text-decoration: none;
      font-size: 0.8rem;
    }
  }
}
</style>
