<template>
  <div class="contact">
    <div class="contact__hero__sec">
      <div class="contact__hero__sec__desc">
        <h1>Kontakt oss</h1>
        <p></p>
      </div>
    </div>
    <div class="contact__form__wrap">
      <div class="contact__form_control">
        <h2>Kontakt oss</h2>
        <h3>
          Kontakt oss gjerne hvis du har et prosjekt du trenger hjelp til. Vi
          søker stadig etter nye tomter og eiendomer for å kjøpe eller utvikle
          sammen med andre.
        </h3>
        <form ref="form" @submit.prevent="sendEmail">
          <div class="form-input-wrap">
            <input
              type="text"
              id="name"
              class="form-input"
              name="name"
              v-model="name"
              placeholder="Navn"
              required
            />
            <input
              type="email"
              id="email"
              class="form-input"
              v-model="email"
              placeholder="E-post"
              required
              name="email"
            />
          </div>

          <input
            type="text"
            id="subject"
            class="form-input"
            v-model="subject"
            placeholder="Emne"
            required
            name="subject"
          />
          <textarea
            id="message"
            v-model="message"
            placeholder="Beskjed"
            class="form-message"
            name="message"
            required
          ></textarea>

          <button class="btn-contact" type="submit" value="Send">
            Send
            <span v-if="loading">
              <v-progress-circular
                indeterminate
                color="#0d3432"
                :size="20"
              ></v-progress-circular
            ></span>
          </button>
        </form>
        <span v-if="showMsg">
          <v-alert class="mt-2 mb-4" color="#ddecbe" dense text type="success">
            E-posten ble sendt
          </v-alert></span
        >
        <span v-if="showError"
          ><v-alert dense outlined class="mt-2 mb-4" type="error">
            Feil
          </v-alert></span
        >
      </div>
      <div class="contact__address">
        <div class="box">
          <h3>Torstein Fosse</h3>
          <div class="phone">
            <strong>Tif:</strong>
            <a href="tel:+4746137000">+47 461 37 000</a>
          </div>
          <div class="email">
            <strong>E-post:</strong>
            <a href="mailto:torstein@boligogbyggutvikling.no"
              >torstein@boligogbyggutvikling.no</a
            >
          </div>
        </div>
        <div class="box">
          <h3>Geir Morten Foss</h3>
          <div class="phone">
            <strong>Tif:</strong><a href="tel:++4746471477">+47 464 71 477</a>
          </div>
          <div class="email">
            <strong>E-post:</strong>
            <a href="mailto:byggeleder@boligogbyggutvikling.no"
              >byggeleder@boligogbyggutvikling.no</a
            >
          </div>
        </div>
        <div class="box">
          <h3>Kontaktdetaljer</h3>
          <div class="phone">
            <strong>Adresse:</strong
            ><a
              href="https://www.google.com/maps/place/Salhusvegen+55"
              target="_blank"
              >Salhusvegen 55 5131 Bergen</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="contact__google__map">
      <img :src="img" alt="kart" />
    </div>
  </div>
</template>
<script>
import emailjs from "@emailjs/browser";

export default {
  data: () => ({
    img: require("@/assets/map.png"),
    showMsg: false,
    msg: false,
    showError: false,
    error: "",
    name: "",
    email: "",
    subject: "",
    message: "",
    loading: false,
  }),
  methods: {
    sendEmail() {
      this.loading = true;
      emailjs
        .sendForm(
          "service_gccufrb",
          "template_3ngs87l",
          this.$refs.form,
          "KavFIhUl6Zku69mA8"
        )
        .then(
          (result) => {
            this.showMsg = true;
            this.msg = result.text;

            this.name = "";
            (this.email = ""), (this.subject = ""), (this.message = "");
            this.loading = false;
            setTimeout(() => {
              this.showMsg = false;
            }, 3000);
          },
          (error) => {
            this.error = error.text;
            this.showError = true;
            setTimeout(() => {
              this.showError = false;
            }, 3000);
          }
        );
    },
  },
  mounted() {
    if (this.msg) {
      setTimeout(() => {
        document.querySelector(".v-alert").style.display = "none";
      }, 3000);
    }
  },
};
</script>
<style lang="less" scoped>
@import "../styles/global.less";
.contact {
  overflow: hidden !important;
  &__hero__sec {
    background-image: linear-gradient(
        0deg,
        rgba(25, 85, 82, 0.9),
        rgba(19, 67, 65, 0.8),
        rgba(19, 67, 65, 0.6)
      ),
      url("../assets/contact.jpg");
    background-color: @primary;
    min-height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &__desc {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      h1 {
        text-align: center;
        color: @light;
        font-weight: 700;
        font-size: 2.5rem;
        @media (max-width: 700px) {
          font-size: 2.1rem;
          line-height: 1.5;
        }
      }
      p {
        text-align: center;
        line-height: 1.8;
        color: @card;
        font-size: 1.3rem;
        max-width: 600px;
        margin: 0 auto;
        @media (max-width: 700px) {
          width: 100%;
          font-size: 1.1rem;
          line-height: 1.5;
        }
      }
    }
  }
  &__form__wrap {
    max-width: 1140px;
    margin: 0 auto;
    display: grid;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(2, 60% 36%);
    margin-top: 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
    .contact__form_control {
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: @dark;
        margin-bottom: 1rem;
        text-transform: normal;
      }
      h3 {
        font-weight: 300;
        font-size: 1.1rem;
        margin-bottom: 1.2rem;
      }
      .form-input-wrap {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        .form-input {
          display: block;
          width: 100%;
          border: 1px solid rgba(241, 244, 236, 1);
          padding: 8px;
          margin-bottom: 15px;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 300;
          &:focus {
            outline: none;
          }
        }
        @media (max-width: 700px) {
          display: block;
          width: 100%;
        }
      }
      .form-input {
        display: block;
        width: 100%;
        border: 1px solid rgba(241, 244, 236, 1);
        padding: 8px;
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }

      .form-message {
        width: 100%;
        height: 200px;
        border: 1px solid rgba(241, 244, 236, 1);
        padding: 5px 8px;
        border-radius: 5px;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
      .btn-contact {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        width: 150px;
        background: linear-gradient(120deg, #adc57d 50%, @secondary);
        background-position: 0 0;
        background-size: 200% 100%;
        height: 45px;
        border-radius: 3rem !important;
        margin: 1rem 0;
        &:hover {
          background-position: 100% 0;
        }
      }
      @media (max-width: 900px) {
        box-shadow: none;
        padding: 0;
        border-radius: none;
      }
    }
    .contact__address {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: left;
      background-color: rgba(240, 240, 242, 0.6);
      height: 410px;
      padding: 2rem 1rem;
      .box {
        h3 {
          font-weight: 600;
          font-size: 1.05rem;
        }
        .phone {
          a {
            text-decoration: none;
            color: @dark;
            display: inline-block;
            margin-left: 5px;
            font-size: 15px;
            white-space: nowrap;
            display: inline-block;
          }
        }
        .email {
          a {
            text-decoration: none;
            color: @dark;
            display: inline-block;
            margin-left: 5px;
            font-size: 15px;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
      @media (max-width: 900px) {
        justify-items: start;
        padding: 1rem;
        padding-top: 3rem;
      }
    }

    @media (max-width: 900px) {
      display: block;
      width: 100%;
      padding: 0 1rem;
    }
  }
  &__google__map {
    margin-bottom: 2rem;
    max-width: 1140px;
    margin: 0 auto;
    padding: 1rem;
    img {
      width: 100%;
      height: 340px;
      object-fit: cover;
    }
    @media (max-width: 600px) {
      display: block;
      width: 100%;
    }
  }
}
</style>
