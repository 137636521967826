<template>
  <div class="hero-section">
    <div class="hero-section__card">
      <transition
        appear
        enter-active-class="animate__animated animate__fadeInDown"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div class="hero-section__card__title">
          <h1>
            Velkommen til
            <span class="title__gradient">BB</span> Utvikling
          </h1>
        </div>
      </transition>
      <div class="hero-section__card__desc">
        <p>Vi prosjekterer, bygger og selger kvalitetsboliger</p>
      </div>
      <div class="hero-section__card__button">
        <v-btn
          class="hero-section__card__button__read"
          @click="$router.push('/omoss')"
          x-large
          >Les mer</v-btn
        >
        <v-btn
          class="hero-section__card__button__project"
          @click="$router.push('/prosjekter')"
          x-large
          >Prosjekter</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    randomNumber: [],
  }),
};
</script>
<style lang="less" scoped>
@import "../../styles/global.less";
.hero-section {
  position: relative;
  background-color: #274846;
  background-image: linear-gradient(
      0deg,
      rgba(19, 67, 65, 0.5),
      rgba(19, 67, 65, 0.5)
    ),
    url("../../assets/hero.jpg");
  width: 100vw;
  height: 70vh;
  min-height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  &__card {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    &__title {
      h1 {
        font-size: 2.3rem;
        color: @light;
        font-weight: 700;
        white-space: nowrap;
        word-spacing: 7px;
        @media (max-width: 600px) {
          font-size: 1.5rem;
          white-space: nowrap;
        }
        .title__gradient {
          position: relative;
          &::after {
            content: "";
            width: 63px;
            height: 60px;
            position: absolute;
            top: -5px;
            left: -8px;
            background: rgba(221, 236, 190, 1);
            border-radius: 50%;
            opacity: 0.1;
            z-index: -45;
            @media (max-width: 600px) {
              width: 50px;
              height: 50px;
              top: -10px;
              left: -8px;
            }
          }
        }
      }
    }
    &__desc {
      p {
        width: 350px;
        margin: 0 auto;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.5;
        color: @light;
        @media (max-width: 600px) {
          font-size: 1.2rem;
        }
      }
    }
    &__button {
      width: 350px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      margin-top: 1rem;
      &__read {
        text-transform: none;
        font-size: 1rem;
        font-weight: 400;
        background: linear-gradient(120deg, #adc57d 50%, @secondary);
        background-position: 0 0;
        background-size: 200% 100%;
        height: 45px;
        border-radius: 3rem !important;
        &:hover {
          background-position: 100% 0;
        }
      }
      &__project {
        text-transform: none;
        font-size: 1rem;
        font-weight: 400;
        background-color: rgba(19, 67, 65, 0.3);
        color: @light;
        height: 45px;
        border: 1px solid @card;
        border-radius: 3rem !important;
      }
    }
  }
  @media (max-width: 900px) {
    height: 60vh;
  }
}
</style>
