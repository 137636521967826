<template>
  <div class="project-single">
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <div class="back-link">
      ← <router-link to="/prosjekter">Tilbake til prosjekter</router-link>
    </div>
    <div v-if="data">
      <div class="project-single__image__grid">
        <div class="project-single__image__grid__box">
          <img
            class="image-grid-col-2"
            :src="data.images[0].src"
            alt="imageone"
            @click="showMultiple"
          />
        </div>
        <div class="project-single__image__grid__box">
          <img
            @click="showMultiple"
            class="image-grid-col-3"
            :src="data.images[1].src"
            alt=""
          />
        </div>
        <div class="project-single__image__grid__box">
          <img
            @click="showMultiple"
            class="image-grid-col-4"
            :src="data.images[2].src"
            alt=""
          />
        </div>
      </div>
      <h2 class="project-single__title">{{ data.location }}</h2>
      <div class="project-single__overview">
        <div class="project-single__overview__all">
          <div class="box">
            <p class="name">Status:</p>
            <p class="value">{{ data.status }}</p>
          </div>
          <div class="box">
            <p class="name">Salg:</p>
            <p class="value">{{ data.salg }} MNOK</p>
          </div>
          <div class="box">
            <p class="name">Hva bygges?</p>
            <p class="value">{{ data.bygges }}</p>
          </div>
          <div class="box">
            <p class="name">Antall enheter:</p>
            <p class="value">{{ data.qty }} stk</p>
          </div>
        </div>
        <div class="project-single__overview__sidebar">
          <div>
            <h3>Salg:</h3>
            <p>{{ data.salg }} MNOK</p>
          </div>
        </div>
      </div>
      <div class="project-single__descriptions">
        <p>
          {{ data.descOne }}
        </p>
        <br />
        <p>
          {{ data.descTwo }}
        </p>
        <br />
        <p v-if="data.descThree">{{ data.descThree }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import VueEasyLightbox from "vue-easy-lightbox";
import projects from "../../db/index.js";
export default {
  components: { VueEasyLightbox },
  props: ["url"],

  data: () => ({
    projects: projects,
    data: null,
    images: [],
    imgs: "",
    visible: false,
    index: 0,
  }),

  methods: {
    showSingle(src) {
      this.imgs = src;
      this.show();
    },
    showMultiple() {
      this.imgs = this.images;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },

  mounted() {
    for (let i in this.projects) {
      if (this.projects[i].url == this.$route.params.url) {
        const dataImages = this.projects[i].images;
        this.images = dataImages;
        this.data = this.projects[i];
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../styles/global.less";

.project-single {
  max-width: 1140px;
  margin: 0 auto;
  padding: 16px;
  margin-top: 5rem;
  margin-bottom: 2rem;
  .back-link {
    margin-bottom: 1rem;
    a {
      text-decoration: none;
      color: @primary;
    }
  }
  .vel-modal {
    background: rgba(0, 0, 0, 0.7) !important;
    .vel-img-wrapper {
      cursor: pointer;

      .vel-img {
        width: 95vw;
        height: 95vh;
      }
    }
  }

  &__title {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: @dark;
  }
  &__image__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    &__box {
      &:nth-child(1) {
        grid-row: span 2;
        @media (max-width: 900px) {
          grid-column: span 3;
          height: 300px;
        }
      }
      &:nth-child(2) {
        height: 200px;
        @media (max-width: 900px) {
          height: 200px;
        }
      }
      &:nth-child(3) {
        height: 250px;
        @media (max-width: 900px) {
          height: 200px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
  &__overview {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 65% 30%);

    gap: 2rem;
    margin-top: 1rem;
    &__all {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      align-items: center;
      .box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        .name {
          font-weight: 400;
          font-size: 1rem;
          text-align: left;
          white-space: nowrap;
        }
        .value {
          font-weight: 500;
          font-size: 1rem;
          text-align: right;
          white-space: nowrap;
          padding: 0 5px;
        }
        @media (max-width: 700px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
      }
    }
    &__sidebar {
      text-align: center;
      border: 1px solid rgba(13, 52, 50, 0.2);
      border-radius: 10px;
      padding: 5px 0;
      h3 {
        font-weight: 300;
        font-size: 1rem;
      }
      p {
        font-weight: 700;
        font-size: 1.3rem;
        color: @dark;
      }
      @media (max-width: 800px) {
        margin-top: 1rem;
        width: 45%;
        margin-bottom: 1rem;
      }
    }
    @media (max-width: 800px) {
      display: block;
      width: 100%;
    }
  }
  &__descriptions {
    max-width: 650px;
    margin-top: 1rem;
    p {
      line-height: 1.8;
      font-weight: 400;
      color: @dark;
      font-size: 1rem;
    }
  }
}
</style>
