import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueEasyLightbox from "vue-easy-lightbox";
import "./styles/global.less";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueEasyLightbox)
  .mount("#app");
