<template>
  <div class="about">
    <div class="about__hero__sec">
      <h1>Om oss</h1>
    </div>
    <div class="about__desc">
      <h2 data-aos="fade-in" data-aos-duration="2000">
        Vi bygger og utvikler eiendomsprosjekter, med fokus på kostnadseffektiv
        småhusbebyggelse som 2-mannsboliger, flermannsboliger og rekkehus.
      </h2>
      <div class="about__desc__subsection">
        <div class="description">
          <h3>BB Utvikling strategi</h3>
          <p>
            Vi bygger og utvikler eiendomsprosjekter, med fokus på
            kostnadseffektiv småhusbebyggelse som 2-mannsboliger,
            flermannsboliger og rekkehus. Vi hjelper deg å gjøre den om til
            enkle og raske penger!
          </p>
        </div>
        <div class="img" data-aos="fade-in" data-aos-duration="2000">
          <svg
            width="190"
            height="200"
            viewBox="0 0 250 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style=""
          >
            <path
              d="M131.126 3.35489L131.127 3.35562L182.012 50.6205L183.692 52.1815V49.8878V26.8C183.692 24.7223 184.581 22.7132 186.189 21.2197C187.8 19.7239 190 18.872 192.308 18.872H211.538C213.846 18.872 216.046 19.7239 217.657 21.2197C219.266 22.7132 220.154 24.7223 220.154 26.8V85.5998V86.0361L220.474 86.3328L246.511 110.491C246.511 110.491 246.511 110.491 246.512 110.491C247.308 111.233 247.934 112.108 248.36 113.066C248.785 114.024 249.002 115.047 249 116.078V116.08V241.072C249 243.15 248.112 245.159 246.503 246.652C244.892 248.148 242.692 249 240.385 249H153.846C151.538 249 149.338 248.148 147.727 246.652C146.119 245.159 145.231 243.15 145.231 241.072V169.648V168.648H144.231H105.769H104.769V169.648V241.072C104.769 243.15 103.881 245.159 102.273 246.652C100.662 248.148 98.4617 249 96.1538 249H9.6154C7.30751 249 5.1077 248.148 3.49672 246.652C1.88825 245.159 1.00002 243.15 1.00002 241.072L1.00002 116.08L1.00001 116.078C0.99803 115.047 1.21482 114.024 1.64023 113.066C2.06574 112.108 2.69267 111.232 3.48906 110.491L118.873 3.35575L118.874 3.35489C119.671 2.61272 120.622 2.0203 121.674 1.6149C122.727 1.20947 123.857 1 125 1C126.143 1 127.273 1.20947 128.326 1.6149C129.378 2.0203 130.329 2.61272 131.126 3.35489ZM18.2308 232.144V233.144H19.2308H86.5385H87.5385V232.144V160.72C87.5385 158.642 88.4267 156.633 90.0352 155.14C91.6462 153.644 93.846 152.792 96.1538 152.792H153.846C156.154 152.792 158.354 153.644 159.965 155.14C161.573 156.633 162.462 158.642 162.462 160.72V232.144V233.144H163.462H230.769H231.769V232.144V119.776V119.34L231.45 119.043L125.68 20.8353L125 20.2036L124.32 20.8353L18.5504 119.043L18.2308 119.34V119.776V232.144Z"
              stroke="#929E78"
              stroke-width="2"
              class="JoDaqhKG_0"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div></div>
    <div class="about__card__sec">
      <h3>Vår team</h3>
      <div class="about__card__sec__wrap">
        <div class="box" data-aos="fade-in" data-aos-duration="2000">
          <div class="box-img">
            <img :src="profilePic" />
          </div>

          <h4>Torstein Fosse</h4>
          <div>Kundeansvarlig</div>
          <div><a href="tel:+4746137000" class="phone">+47 461 37 000</a></div>
          <div>
            <a href="mailto:email@example.com" class="email"
              >post@boligogbyggutvikling.no</a
            >
          </div>
        </div>
        <div class="box" data-aos="zoom-in" data-aos-duration="1000">
          <div class="box-img">
            <img :src="profileTo" />
          </div>

          <h4>Geir Morten Foss</h4>
          <div>Byggeleder</div>
          <div><a href="tel:+4746471477" class="phone">+47 46 47 14 77</a></div>
          <div>
            <a href="mailto:post@boligogbyggutvikling.no" class="email"
              >post@boligogbyggutvikling.no</a
            >
          </div>
        </div>
        <div class="box" data-aos="zoom-in" data-aos-duration="2000">
          <div class="box-img">
            <img :src="profilePic" />
          </div>

          <h4>Janathan Samantha</h4>
          <div>Utvikler</div>
          <div><a href="tel:+4746137000" class="phone">+47 461 37 000</a></div>
          <div>
            <a href="mailto:post@boligogbyggutvikling.no" class="email"
              >post@boligogbyggutvikling.no</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    heroImg: require("@/assets/noimage.png"),
    profilePic: require("@/assets/noimage.png"),
    profileTo: require("@/assets/noimage.png"),
    aboutImg: require("@/assets/about.png"),
  }),
};
</script>
<style lang="less" scoped>
@import "../styles/global.less";
.JoDaqhKG_0 {
  stroke-dasharray: 1968 1970;
  stroke-dashoffset: 1969;
  animation: JoDaqhKG_draw_0 11100ms linear 0ms infinite,
    JoDaqhKG_fade 11100ms linear 0ms infinite;
}
@keyframes JoDaqhKG_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes JoDaqhKG_fade {
  0% {
    stroke-opacity: 1;
  }
  99.09909909909909% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
@keyframes JoDaqhKG_draw_0 {
  4.504504504504505% {
    stroke-dashoffset: 1969;
  }
  64.56456456456456% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.about {
  max-width: 1140px;
  margin: 0 auto;
  padding: 1rem;
  &__hero__sec {
    h1 {
      padding-top: 5rem;
      color: @dark;
      font-size: 2rem;
      font-weight: 500;
      @media (max-width: 700px) {
        font-size: 2.1rem;
        line-height: 1.5;
      }
    }
  }
  &__desc {
    margin-bottom: 4rem;
    h2 {
      text-align: left;
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 1.7;
      margin: 1rem 0;
      max-width: 700px;
      @media (max-width: 700px) {
        font-size: 1.2rem;
      }
    }
    &__subsection {
      max-width: 1140px;
      display: grid;
      grid-template-columns: repeat(2, 60% 40%);
      gap: 1rem;
      justify-content: left;
      align-items: center;
      .description {
        h3 {
          text-align: left;
          font-size: 1.4rem;
          font-weight: 500;
          margin: 1rem 0;
          max-width: 700px;
          @media (max-width: 700px) {
            font-size: 1.2rem;
          }
        }
        p {
          line-height: 2;
          font-size: 18px;
          color: @dark;
        }
      }
      .img {
        text-align: right;
        width: 200px;
        height: 200px;
        overflow: hidden;
        align-self: right;
        display: block;
        margin-left: 150px;
        @media (max-width: 900px) {
          margin-left: 20px;
          margin-top: 1rem;
        }
      }
      @media (max-width: 800px) {
        display: block;
        width: 100%;
      }
    }
  }

  &__card__sec {
    max-width: 1140px;
    margin: 0 auto;

    h3 {
      font-size: 1.3rem;
      margin-top: 1rem;
      position: relative;
      display: block;
      font-weight: 500;
      &::after {
        position: absolute;
        content: "";
        bottom: -4px;
        left: 3px;
        width: 60px;
        height: 4px;
        background: @borderbottom;
      }
      @media (max-width: 1024px) {
        margin-top: 1rem;
      }
    }

    &__wrap {
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-basis: auto;
      gap: 1rem;
      padding: 2rem 0;

      .box {
        overflow: hidden;
        box-shadow: 0px 1px 4px 0px @secondary;
        background-color: white;
        text-align: center;
        border-radius: 10px;
        flex: 0 0 calc(33% - 10px);
        transition: 0.3s;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        &:hover {
          box-shadow: 0 8px;
        }
        h4 {
          padding: 1rem 0;
        }
        .box-img {
          display: block;
          overflow: hidden;
          max-height: 350px;
          img {
            width: 100%;
            height: 350px;
            object-fit: cover;
          }
        }
        .email {
          color: @dark;
          text-decoration: none;
        }
        .phone {
          color: @dark;
          text-decoration: none;
          margin-top: 10px;
          display: block;
        }
        &:hover {
          box-shadow: 0px 0px 20px 1px #9592927a;
        }
      }
      @media (max-width: 700px) {
        display: block;
      }
    }
  }
}
</style>
