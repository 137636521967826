<template>
  <div class="projects">
    <div class="projects__title__wrap">
      <h1 class="title-first">Nye prosjekter</h1>
      <div class="projects__title__wrap__header__button">
        <button class="btn-project" @click="$router.push('/prosjekter')">
          Alle prosjekter</button
        ><v-icon small size="14" class="btn-greater">mdi-greater-than</v-icon>
      </div>
    </div>
    <v-progress-linear
      v-if="!projects"
      :indeterminate="loading"
      bottom
      top
      color="#ADC47D"
    ></v-progress-linear>

    <div class="projects__wrap" v-if="projects">
      <router-link
        v-for="project in projects"
        :key="project.id"
        :to="{ name: 'ProjectDetail', params: { url: project.url } }"
        class="projects__wrap__card"
      >
        <img
          :src="project.images[0].src"
          class="card-img"
          :alt="project.title"
        />
        <h1 class="title">{{ project.title }}</h1>
        <h4 class="location">
          <a
            :href="`https://www.google.com/maps/place/${project.location}`"
            target="_blank"
            ><v-icon small color="#b7d57b" size="24"> mdi-map-marker </v-icon
            >{{ project.location }}</a
          >
        </h4>
        <h3 class="sub-des">Salg {{ project.salg }} MNOK</h3>
        <p class="description">
          {{ project.summary }}
        </p>
        <v-divider class="mx-4"></v-divider>
        <button class="read-more-btn">
          <router-link
            :to="{ name: 'ProjectDetail', params: { url: project.url } }"
            class="btn btn-primary"
            >Les mer</router-link
          >
          <v-icon small size="14" class="btn-greater">mdi-greater-than</v-icon>
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import projects from "../../db/index.js";
export default {
  data: () => ({
    projects: projects,
  }),
};
</script>
<style lang="less" scoped>
@import "../../styles/global.less";
.projects {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
  &__title__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    justify-items: stretch;
    align-items: center;

    &__header__button {
      width: 100%;
      text-align: right;

      .btn-project {
        font-size: 1.2rem;
        font-weight: 400;
        color: @dark;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0%;
          width: 100%;
          height: 3px;
          background: @borderbottom;
          z-index: 1;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform 0.5s ease-in-out;
        }
        &:hover::before {
          transform: scaleX(1);
          display: inline-block;
        }
        @media (max-width: 600px) {
          font-size: 1.1rem;
        }
      }
      .btn-greater {
        margin-top: -2px;
        margin-left: 6px;
        font-weight: bold;
      }
    }
    .title-first {
      font-size: 1.4rem;
      font-weight: 600;
      color: @dark;
      letter-spacing: 10;
      text-align: left;
      position: relative;
      display: block;
      margin-bottom: 1.2rem;
      &::after {
        position: absolute;
        content: "";
        bottom: -8px;
        left: 0;
        width: 60px;
        height: 4px;
        background: @borderbottom;
      }
      @media (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
  }
  &__wrap {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: auto;
    margin-top: 1.5rem;
    gap: 1rem;
    &__card {
      text-decoration: none;
      overflow: hidden;
      box-shadow: 0px 1px 4px 0px @secondary;
      background-color: white;
      text-align: center;
      border-radius: 10px;
      flex: 0 0 calc(33% - 10px);
      margin-bottom: 1rem;
      transition: ease 0.5s;
      transform: translateY(0);
      cursor: pointer;
      &:hover {
        -ms-transform: translateY(-1.2rem);
        -webkit-transform: translateY(-1.2rem);
        transform: translateY(-1.2rem);
        opacity: 0.9;
      }
      .card-img {
        height: 250px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
      }

      .title {
        font-weight: 500;
        font-size: 1.2rem;
        text-align: left;
        padding: 5px 12px;
        color: @dark;
      }
      .location {
        font-weight: 400;
        text-align: left;
        padding: 0 12px;
        a {
          text-decoration: none;
          color: @dark;
          padding: 0;
        }
      }
      .sub-des {
        font-weight: 500;
        font-size: 1rem;
        margin: 10px 0;
        text-align: left;
        padding: 0 12px;
        color: @dark;
      }
      .description {
        font-size: 0.9rem;
        text-align: left;
        padding-left: 12px;
        padding-bottom: 12px;
        color: @dark;
        font-weight: 300;
      }
      @media (max-width: 900px) {
        flex-basis: 100%;
      }
      .read-more-btn {
        display: block;
        margin-left: 1rem;
        margin-top: 6px;
        margin-bottom: 6px;
        font-size: 1rem;
        font-weight: 400;
        .btn {
          text-decoration: none;
          color: @primary;
          display: inline-block;
          margin-right: 3px;
        }
        .btn-greater {
          margin-top: -2px;
          margin-left: 6px;
          font-weight: bold;
          color: @dark;
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      flex-basis: 100%;
    }
  }
}
</style>
