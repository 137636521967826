<template>
  <div class="not-found">
    <h1>404</h1>
    <h2>FANT IKKE SIDEN DU SPURTE ETTER!!</h2>
  </div>
</template>
<style lang="less" scoped>
.not-found {
  margin: 8rem 0;
  h1 {
    font-size: 3rem;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    text-transform: capitalize;
  }
}
</style>
