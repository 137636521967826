const projects = [
  {
    id: 1,
    title: "G.Foss Eiendom AS",
    url: "G.Foss-Eiendom-AS",
    location: "Hetlevikstraumen 8, 5173 Loddefjord",
    salg: "13,2",
    summary: "Prosjektet består av 2 akritekttendende eneboliger",
    status: "Bygging startet",
    bygges: "Eneboliger",
    qty: "2",
    descOne:
      "I dette prosjektet oppføres det to nye moderne og arkitekttegnede eneboliger. De to eneboligene som skal bygges planlegges å være 182 kvm og 142 kvm med adresse Hetlevikstraumen 8 og 10. Bolig og Byggutvikling AS vil fungere som totalentreprenør i prosjektet og planlegger at prosjektet skal være ferdigstilt innen utgangen av 2023.",
    descTwo:
      "Eiendommen ligger i Loddefjord som er et mindre boligområde omtrent 15 minutters kjøretur vest for Bergen sentrum. Boligene som føres opp har en god beliggenhet like ved sjøen midt mellom Vestkanten og Haakonsvern. Det er et barnevennlig boligområde med korte avstander til barnehager, skoler og idrettsanlegg, i tillegg til gode turområde.",
    images: [
      {
        src: require("../assets/img/gfoss/gfoss1.jpg"),
      },
      {
        src: require("../assets/img/gfoss/gfoss2.jpg"),
      },
      {
        src: require("../assets/img/gfoss/gfoss3.jpg"),
      },
    ],
  },

  {
    id: 2,
    title: "Aust Bolig AS",
    url: "Aust-Bolig-AS",
    location: "Kreklingveien 2 - 20",
    salg: "56,7",
    summary:
      "Prosjektet består av 6 stk. moderne og kostandseffektive 2-mannsboliger",
    status: "Bygging startet",
    bygges: "2-mannsboliger",
    qty: "12",
    descOne:
      "I dette prosjektet oppføres det 6 stk. moderne og kostnadseffektive 2-mannsboliger. Boligene planlegges å være 300 kvm med adresse Kreklingveien 2 - 20. Bolig og Byggutvikling AS vil fungere som totalentreprenør i prosjektet og planlegger at prosjektet skal være ferdigstilt innen utgangen av 2024",
    descTwo:
      "Eiendommen ligger i Festningsåsen, et større utbyggingsområde på Bjørkelangen. Bjørkelangen er et tettsted og administrasjonssenter i Aurskog-Høland kommune. Festningsåsen ligger ved nordenden av innsjøen Bjørkelangen, langs Lierelva.",
    descThree:
      "Bjørkelangen ble det administrative senteret i kommunen i 1966. Bjørkelangen har de siste årene opplevd stor utvikling med etablering av videregående skoler, flere betydelige boligområder og sentrumsfunksjoner. Bjørkelangen har et yrende kulturliv og musikkfestival. Det er en rekke idylliske og fiskerike innsjøer, vann og tjern omkring Bjørkelangen. Blant annet Bjørkelangensjøen, Maltjern, Stigrundtjern, Liertjern, Eidsdammen, Stemtjern, Røytjern, Dyntjern, Pååtjenn og drikkevannskilden Langsjøen.",
    images: [
      {
        src: require("../assets/img/bfs2/austbfs2three.jpeg"),
      },
      {
        src: require("../assets/img/bfs2/austbfs2two.png"),
      },
      {
        src: require("../assets/img/bfs2/austbfs2One.jpeg"),
      },
    ],
  },

  {
    id: 3,
    title: "Aust Bolig BFS2 AS",
    url: "Aust-Bolig-BFS2-AS",
    location: "Festningslia 18 - 24",
    salg: "18,9",
    summary:
      "På toppen av Bjørelangen bygges 4 enehter med panorama utsikt over Bjørkelangen.",
    status: "Ferdig",
    bygges: "2-mannsboliger",
    qty: "4",
    images: [
      {
        src: require("../assets/img/bfs2/austbfs2two.png"),
      },
      {
        src: require("../assets/img/bfs2/austbfs2One.jpeg"),
      },
      {
        src: require("../assets/img/bfs2/austbfs2three.jpeg"),
      },
    ],
    descOne:
      "I dette prosjektet oppføres det moderne og kostnadseffektive 2-mannsboliger. Boligene som skal bygges har panoramautsikt utover Bjørkelangenene og planlegges å være 300 kvm med adresse Festningslia 18- 24. Bolig og Byggutvikling AS vil fungere som totalentreprenør i prosjektet og planlegger at prosjektet skal være ferdigstilt innen utgangen av 2022 ",
    descTwo:
      "Eiendommen ligger i Festningsåsen, et større utbyggingsområde på Bjørkelangen. Bjørkelangen er et tettsted og administrasjonssenter i Aurskog-Høland kommune.",
    descThree:
      "Festningsåsen ligger ved nordenden av innsjøen Bjørkelangen, langs Lierelva. Bjørkelangen ble det administrative senteret i kommunen i 1966. Bjørkelangen har de siste årene opplevd stor utvikling med etablering av videregående skoler, flere betydelige boligområder og sentrumsfunksjoner. Bjørkelangen har et yrende kulturliv og musikkfestival. Det er en rekke idylliske og fiskerike innsjøer, vann og tjern omkring Bjørkelangen. Blant annet Bjørkelangensjøen, Maltjern, Stigrundtjern, Liertjern, Eidsdammen, Stemtjern, Røytjern, Dyntjern, Pååtjenn og drikkevannskilden Langsjøen.",
  },
];
export default projects;
