// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import { createVuetify } from "vuetify";

export default createVuetify({
  icons: {
    iconfont: "md", // default - only for display purposes
  },
});
