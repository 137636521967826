import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Omoss from "../views/AboutView.vue";
import PageNotFound from "../components/notfound/pageNotFound.vue";
import ProjectLists from "../views/ProjectLists.vue";
import Contact from "../views/ContactView.vue";
import ProjectDetail from "../views/projectdetails/projectDetail.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/omoss",
    name: "omoss",
    component: Omoss,
  },
  {
    path: "/prosjekter",
    name: "ProjectLists",
    component: ProjectLists,
  },

  {
    path: "/kontakt",
    name: "contact",
    component: Contact,
  },
  {
    path: "/prosjekter/:url",
    name: "ProjectDetail",
    component: ProjectDetail,
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
