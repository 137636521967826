<template>
  <div class="our-task">
    <div class="our-task__wraper">
      <div
        class="our-task__wraper__desc__sec"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <h1>Har du stor hage?</h1>
        <p>
          Vi hjelper deg å gjøre den om til enkle og raske penger! les mer hvis
          du trenger hvordan vi kan hjelpe deg.
        </p>
        <div class="btn-wrap">
          <button class="btn-project" @click="$router.push('/')">Les mer</button
          ><v-icon small size="14" class="btn-greater">mdi-greater-than</v-icon>
        </div>
      </div>
      <div
        class="our-task__wraper__img__sec"
        data-aos="fade-left"
        data-aos-duration="2000"
      >
        <img :src="imageCard" alt="task image" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    imageCard: require("@/assets/task.jpeg"),
  }),
};
</script>
<style lang="less" scoped>
@import "../../styles/global.less";
.our-task {
  width: 100%;
  background-color: @lightbackground;
  padding: 2rem 0;
  &__wraper {
    max-width: 1140px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
    &__desc__sec {
      padding: 0 1rem;
      h1 {
        font-weight: 400;
        font-size: 30px;
        line-height: 38px;
        color: @dark;
        padding: 1rem 0;
        max-width: 400px;
      }
      p {
        font-size: 1.1rem;
        font-weight: 300;
        line-height: 33px;
        max-width: 480px;
        color: @primary;
      }
      .btn-wrap {
        width: 100%;
        text-align: left;
        .btn-project {
          font-weight: 500;
          font-size: 1.1rem;
          color: @dark;
          position: relative;
          margin: 1rem 0;
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0%;
            width: 100%;
            height: 3px;
            background: @borderbottom;
            z-index: 1;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.5s ease-in-out;
          }
          &:hover::before {
            transform: scaleX(1);
            display: inline-block;
          }
        }
        .btn-greater {
          margin-top: -2px;
          margin-left: 8px;
          font-weight: bold;
        }
      }
    }

    .lists-task {
      list-style-type: square;
      padding: 0 1rem;
      li {
        color: @dark;
        padding: 5px 0;
        font-size: 1.1rem;
      }
    }
    &__img__sec {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    @media (max-width: 800px) {
      display: block;
      width: 100%;
    }
  }
}
</style>
